import api from '@/repo/api';
import auth from "@/auth";
import request from '@/repo/request';

export class UploadImage {
  // imageUrl = ""
  action = api.colUrl('/image/upload')
  // maxLength = 9
  // 上传最大字节 默认2M
  maxByte = 2097152
  usage = ""
  /**
   * 
   * @param {{maxByte?: number; usage?: string}?} options 
   */
  constructor(options) {
    if (options?.maxByte) {
      this.maxByte = options.maxByte
    }
    if (options?.usage) {
      this.usage = options.usage
    }
  }
  /**
   * 
   * @param {File | Blob} file 
   * @returns 
   */
  async upload(file) {
    const errs = []
    if (!this._validate(file, errs)) {
      throw errs[0]
    }
    const url = this._buildFullAction()
    const f = new FormData()
    f.append("image", file)
    return new Promise((resolve, reject) => {
      request.post(url, f, (resData) => {
        if (resData.code === 0) {
          resolve({
            url: resData.data,
          })
        } else {
          reject(new Error(resData.msg))
        }
      }, (err) => {
        reject(err)
      })
    })
  }
  async choose() {
    return new Promise((resolve, reject) => {
      window.addEventListener('focus', onFocus)
      // window.addEventListener("blur", onBlur)

      const el = document.createElement("input")
      el.type = "file"
      el.onchange = (e) => {
        const file = e.target.files[0]
        resolve(file)

        setTimeout(() => {
          window.removeEventListener('focus', onFocus)
          // window.removeEventListener('blur', onBlur)
        }, 501)
      }
      const event = new MouseEvent("click")
      el.dispatchEvent(event)

      function onFocus() {
        setTimeout(() => {
          const isChoose = !!el.files.length
          if (isChoose) return
          reject(new Error('未选择文件'))
        }, 500)
      }
      // function onBlur() {}
    })
  }
  /**
   * 
   * @param {File} file 
   * @param {Array<Error>} errs
   * @returns {boolean}
   */
  _validate(file, errs) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      errs.push(new Error('你只能上传jpg,png文件!'))
      return false
    }
    if (this.maxByte !== Infinity) {
      const isLt1M = file.size / this.maxByte < 1
      if (!isLt1M) {
        errs.push(new Error('图片必须小于2M!'))
        return false
      }
    }
    return true
  }
  /**
   * 
   * @returns {string}
   */
  _buildFullAction() {
    let appId = localStorage['mini_app_id'];

    if (this.action.indexOf('?') === -1) {
      return this.action + '?token=' + auth.getToken() + '&_mini_app_id=' + appId + '&usage=' + this.usage
    }

    return this.action + '&token=' + auth.getToken() + '&_mini_app_id=' + appId + '&usage=' + this.usage
  }
}