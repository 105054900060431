<script>
import Poster from "@/components/Poster"

export default {
  components: {
    Poster,
  },
  props: {
    /** 控制隐藏 */
    value: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    value() {
      // if (value && this.$refs.poster) {
      //   this.$refs.poster.reset()
      // }
    },
  },
  methods: {
    handleCancel() {
      this.$emit('input', false)
    },    
  },
}
</script>

<template>
  <a-modal
    title="分享海报内容设置"
    :visible="value"
    :bodyStyle="{paddingTop: 0, paddingBottom: 0}"
    :width="423"
    :footer="false"
    @cancel="handleCancel"
  >
    <Poster v-if="value" v-bind="$attrs" v-on="$listeners"></Poster>
  </a-modal>
</template>

<style lang="scss" scoped>
.tip {
  margin: 16px 0 24px 0;
  display: flex;
  align-items: center;
  &-dot {
    width: 8px;
    height: 8px;
    background-color: #F5606D;
    border-radius: 50%;
    margin: 0 8px;
    display: inline-block;
  }
  &-content {
    font-size: 16px;
    color: #999999;
  }
}
.poster {
  position: relative;
  &-background {
    // background-color: green;
    background-image: var(--background-image);
    width: var(--background-w);
    height: var(--background-h);
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0,0,0,0.2);
    }
  }
  &-qrcode {
    position: absolute; 
    z-index: 2;
    left: var(--qrcode-l);
    top: var(--qrcode-t);
    width: var(--qrcode-w); 
    height: var(--qrcode-h);

    background: rgba(0,0,0,0.4);
    border: 1px solid #000000;
    cursor: pointer;
  }
}
.submit-row {
  display: flex;
  justify-content: center;
  position: relative;
  .submit-btn {
    font-size: 14px;
    color: white;
    width: 114px;
    height: 40px;
    line-height: 40px;
    background: #333333;
    margin: 40px 0 32px 0;
  }
}
</style>