<script>
import Cropper from "cropperjs";
import {dataURLtoBlob, dataURLtoFile} from "@/utils/convert"

export default {
  props: {
    imageUrl: {
      type: String,
      default: "",
      // default: "https://static-bang.ycsd.work/life-photos/589/b56b4c4fb01868b72728b1a64b08d880.png",
    },
    aspectRatio: {
      type: Number,
      default: 114 / 149,
    },
    /** 裁剪后的数据格式 blob、file、base64 */
    croppedFormat: {
      type: String,
      default: "file",
    },
  },
  data() {
    return {
      cropperDetail: {},
      lifePhotos: [],
      pickerList: [],
      cer: null,
      spfzd: 1,
      czfzd: 1,
      cropperImageIdx: 0,
    }
  },
  beforeDestroy() {
    this.cer.destroy()
    this.cer = null
  },
  methods: {
    initCropper() {
      let el = this.$refs.cropperImage
      let preview = this.$refs.cropperImagePreview

      this.cer = new Cropper(el, {
          aspectRatio: this.aspectRatio,
          preview,
          dragMode: "move",
          crop: (event) => {
              this.cropperDetail = event.detail
          },
      })
    },
    crop() {
      if (!this.cer) return;

      let dta = this.cer.getCroppedCanvas().toDataURL('image/jpeg', 0.8);
      let photos = JSON.parse(JSON.stringify(this.lifePhotos))
      photos.splice(this.cropperImageIdx, 1, dta)
      this.pickerList = photos;
      const base64Url = this.pickerList[0]
      let converted
      switch (this.croppedFormat) {
        case "base64": {
          converted = base64Url
        } break
        case "blob": {
          converted = dataURLtoBlob(base64Url)
        } break
        case "file": {
          converted = dataURLtoFile(base64Url, `tailor${Date.now()}`)
        } break
        default: {
          console.warn("裁剪后的类型不正确")
        }
      }
      this.$emit("success", converted)
    },
    fd() {
      if (!this.cer) return;

      this.cer.scale(
          this.cropperDetail.scaleX * 1.1,
          this.cropperDetail.scaleY * 1.1,
      )
    },
    sx() {
      if (!this.cer) return;

      this.cer.scale(
          this.cropperDetail.scaleX * 0.9,
          this.cropperDetail.scaleY * 0.9,
      )
    },
    spfz() {
      if (!this.cer) return;

      this.spfzd = this.spfzd === -1 ? 1 : -1;

      this.cer.scale(this.spfzd, this.czfzd)
    },
    czfz() {
      if (!this.cer) return;

      this.czfzd = this.czfzd === -1 ? 1 : -1;

      this.cer.scale(this.spfzd, this.czfzd)
    },
    rotateLeft() {
      if (!this.cer) return;

      this.cer.rotate(-90)
    },
    rotateRight() {
      if (!this.cer) return;

      this.cer.rotate(90)
    },
  },
}
</script>

<template>
  <div class="tailor">
    <div class="cropper-img-container">
      <div class="cropper-img-left">
        <div v-if="!imageUrl" class="tailor__emptry">无图片</div>
        <img v-else @load="initCropper" ref="cropperImage" class="cropperImage" :src="imageUrl" alt="">
      </div>
      <div v-if="imageUrl">
        <div ref="cropperImagePreview" class="cropperImagePreview"></div>
        <div class="text-center"><small>（预览图）</small></div>
      </div>
    </div>
    <div class="tailor__footer">
      <a-button-group class="cropper-btns">
        <a-button @click="fd" type="primary">放大</a-button>
        <a-button @click="sx" type="primary">缩小</a-button>
        <a-button @click="spfz" type="primary">水平翻转</a-button>
        <a-button @click="czfz" type="primary">垂直翻转</a-button>
        <a-button @click="rotateLeft" type="primary">向左旋转</a-button>
        <a-button @click="rotateRight" type="primary">向右旋转</a-button>
      </a-button-group>
  
      <a-button type="primary" @click="crop">确认</a-button>
    </div>
  </div>
</template>

<style scoped lang="less">
.tailor {
  // padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__footer {
    display: flex;
    justify-content: space-between;
  }
  &__emptry {
    height: 300px;
    text-align: center;
    line-height: 300px;
  }
}

.lf-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;
}

.lf-img {
    width: 114px;
    height: 149px;

    img {
        width: 1px;
        height: 1px;
        opacity: 0;
    }

    margin-bottom: 15px;

    background-color: black;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;

    margin-right: 15px;
    display: block;
    position: relative;
    cursor: pointer;

    .no {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 20px;
        height: 20px;
        background-color: black;
    }

    &.empty {
        height: 0;
    }

    .lf-link {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #dddddd;
        padding: 2px 5px;
        opacity: 0;
    }

    &:hover .lf-link {
        opacity: 1;
    }
}

.cropper-img-container {
    display: flex;
    flex-direction: row;
}

.cropper-img-left {
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cropperImage {
    max-width: 600px;
    max-height: 500px;
}

.cropperImagePreview {
    width: 228px;
    height: 288px;
    overflow: hidden;
    background: white;
    border: 2px solid #dddddd;
}

.cropper-btns {
    /deep/ button {
        background-color: #333333;
        border-color: #dddddd !important;
    }
}

.lf-img:hover .cropper-tips {
    opacity: 1;
}

.cropper-tips {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(221, 221, 221, 0.42);
    color: white;
    opacity: 0;
}

.lf-uploader {
    width: 114px;
    height: 149px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    border: 1px solid #dddddd;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}

.uploader-text {
    font-size: small;
    text-align: center;

    img {
        margin-bottom: 10px;
    }
}

</style>