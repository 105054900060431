<script>
import _ from "lodash"
import {UploadImage as UploadImageFile} from "@/utils/upload"
import {dataURLtoFile} from '@/utils/convert'
import TailorModal from "@/components/TailorModal"

const STATUS = {
  EMPTY: 1,
  CHOOSE: 2,
  CROP: 3,
  UPLOAD: 4,
}

const createDefault = () => ({
  STATUS,
  pageLoading: false,
  loading: false,
  background: {
    w: 375,
    h: 812,
    imageUrl: "",
  },
  qrcode: {
    w: 80,
    h: 80,
    l: 144,
    t: 644,
  },
  showTailorModal: false,
  /** 操作状态 */
  status: STATUS.EMPTY,
  tempBackgroundImageBase64Url: undefined,
})

async function fileToBase64Url(file) {
  const url = await new Promise((resolve, reject) => {
    const fr = new FileReader()
    fr.onload = () => {
      resolve(fr.result)
    }
    fr.onerror = () => {
      reject(fr.error)
    }
    fr.readAsDataURL(file)
  })
  return url
}

export default {
  components: {
    TailorModal,
  },
  props: {
    /** 同data.background */
    defaultBackground: {
      type: Object,
      default: undefined,
    },
    /** 同data.qrcode */
    defaultQrcode: {
      type: Object,
      default: undefined,
    },
    /** 海报字节数 */
    maxByte: {
      type: Number,
      default: 2 * 1024 * 1024,
    },
  },
  data() {
    return createDefault()
  },
  computed: {
    cssVar() {
      return {
        '--background-image': `url(${this.waitCropImageUrl})`,
        '--background-w': this.background.w + 'px',
        '--background-h': this.background.h + 'px',
        '--qrcode-w': this.qrcode.w + 'px',
        '--qrcode-h': this.qrcode.h + 'px',
        '--qrcode-l': this.qrcode.l + 'px',
        '--qrcode-t': this.qrcode.t + 'px',
      }
    },
    showPreviewPoster() {
      return !(this.status === STATUS.EMPTY && !this.background.imageUrl)
    },
    waitCropImageUrl() {
      return this.tempBackgroundImageBase64Url || this.background.imageUrl
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      if (this.background) {
        Object.assign(this.background, this.defaultBackground)
      }
      if (this.qrcode) {
        Object.assign(this.qrcode, this.defaultQrcode)
      }
    },
    reset() {
      // 先让DOM先初始化
      this.background.imageUrl = ""
      this.tempBackgroundImageBase64Url = undefined
      // 再更新data
      this.$nextTick(() => {
        Object.assign(this, createDefault())
        this.init()
      })
    },
    reducer(status, payload, callback) {
      if (this.loading) return
      switch(status) {
        case STATUS.CHOOSE: {
          this.loading = true
          this.chooseBackground()
          .then(() => {
            this.status = status

            this.handleShowTailorModal()
          })
          .catch(err => {
            console.error(err)
            this.$message.error(err.message)
          })
          .finally(() => {
            this.loading = false
          })
        } break
        case STATUS.CROP: {
          fileToBase64Url(payload)
          .then(url => {
            this.status = status

            this.tempBackgroundImageBase64Url = url
            callback?.()
          })
          .catch(err => {
            console.error(err)
            this.$message.error(err.message)
          })
        } break
        case STATUS.UPLOAD: {
          this.loading = true
          this.uploadBackground()
          .then(() => {
            this.status = status

            this.showTailorModal = false
            callback?.()
          })
          .catch(err => {
            console.error(err)
            this.$message.error(err.message)
          })
          .finally(() => {
            this.loading = false
          })
        } break
      }
    },
    async chooseBackground() {
      const uploadImage = new UploadImageFile({maxByte: this.maxByte})
      const file = await uploadImage.choose()
      this.tempBackgroundImageBase64Url = await fileToBase64Url(file)
    },
    async uploadBackground() {
      if (!this.tempBackgroundImageBase64Url) return
      // 图片不设置字节大小
      const uploadImage = new UploadImageFile({maxByte: Infinity})
      const file = dataURLtoFile(this.tempBackgroundImageBase64Url)
      const {url} = await uploadImage.upload(file)
      this.background.imageUrl = url
      // 更新本地
      this.tempBackgroundImageBase64Url = await fileToBase64Url(file)
    },
    qrcodeMove(e) {
      const el = e.target
      const sX = e.clientX - el.offsetLeft
      const sY = e.clientY - el.offsetTop

      const move = _.throttle(e => {
        const eX = e.clientX - sX
        const eY = e.clientY - sY
        if (0 <= eX && (eX + this.qrcode.w) <= this.background.w) {
          this.qrcode.l = eX
          el.style.left = eX + 'px'
        }
        if (0 <= eY && (eY + this.qrcode.h) <= this.background.h) {
          this.qrcode.t = eY
          el.style.top = eY + 'px'
        }
      }, 16)
      document.onmousemove = move
      document.onmouseup = () => { // 移动结束时的操作
        document.onmousemove = null;
        document.onmouseup = null;

      }
    },
    async handleTailorSuccess(file) {
      this.reducer(STATUS.CROP, file, () => {
        this.showTailorModal = false
      })
    },
    handleShowTailorModal() {
      this.showTailorModal = true
    },
    handleSubmit() {
      this.reducer(STATUS.UPLOAD, null, () => {
        const data = {
          background: Object.assign({}, this.background),
          qrcode: Object.assign({}, this.qrcode),
        }
        this.loading = true
        setTimeout(() => {
          this.loading = false
        }, 500)
        this.$emit("submit", data)
      })
    },
    handleClean() {
      Object.assign(this, createDefault())
      this.$emit("clean")
    },
  },
}
</script>

<template>
  <div :style="cssVar">
    <a-spin :spinning="pageLoading">
      <div class="poster__header" v-if="showPreviewPoster">
        <div>
          <div class="tip">
            <span class="tip-dot"></span>
            <div class="tip-content">
              图片最大不超过2M
            </div>
          </div>
          <div class="tip">
            <span class="tip-dot"></span>
            <div class="tip-content">
              拖动下方二维码确定位置
            </div>
          </div>
        </div>
        <a-button type="danger" @click="handleClean">
          删除
        </a-button>
      </div>
      <div class="poster" v-if="showPreviewPoster">
        <div class="poster-background"></div>
        <img ref="qrcode" class="poster-qrcode" src="/assets/icons/yc-qrcode.png"  @mousedown.prevent="qrcodeMove($event)" />
      </div>
    </a-spin>
    <div class="submit-row">
      <a-button 
        class="submit-btn" 
        type="primary" 
        :loading="loading"
        @click="reducer(STATUS.CHOOSE)"
      >选择背景图</a-button>
      <a-button 
        v-if="showPreviewPoster"
        class="submit-btn" 
        type="primary" 
        :loading="loading"
        style="margin-left: 20px;"
        @click="handleShowTailorModal"
      >裁剪背景图</a-button>
      <a-button 
        v-if="showPreviewPoster"
        class="submit-btn" 
        type="primary" 
        :loading="loading"
        style="margin-left: 20px;"
        @click="handleSubmit"
      >保存海报</a-button>
    </div>

    <TailorModal 
      v-model="showTailorModal"
      :imageUrl="waitCropImageUrl"
      :aspectRatio="background.w/background.h"
      @success="handleTailorSuccess"
    ></TailorModal>
  </div>
</template>

<style lang="scss" scoped>
.tip {
  margin: 16px 0 24px 0;
  display: flex;
  align-items: center;
  &-dot {
    width: 8px;
    height: 8px;
    background-color: #F5606D;
    border-radius: 50%;
    margin: 0 8px;
    display: inline-block;
  }
  &-content {
    font-size: 16px;
    color: #999999;
  }
}
.poster {
  position: relative;
  &-background {
    // background-color: green;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: var(--background-image);
    width: var(--background-w);
    height: var(--background-h);
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0,0,0,0.2);
    }
  }
  &-qrcode {
    position: absolute; 
    z-index: 2;
    left: var(--qrcode-l);
    top: var(--qrcode-t);
    width: var(--qrcode-w); 
    height: var(--qrcode-h);

    background: rgba(0,0,0,0.4);
    border: 1px solid #000000;
    cursor: pointer;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.submit-row {
  display: flex;
  justify-content: center;
  position: relative;
  .submit-btn {
    font-size: 14px;
    color: white;
    width: 114px;
    height: 40px;
    line-height: 40px;
    background: #333333;
    margin: 40px 0 32px 0;
  }
}
</style>